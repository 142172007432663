import React, { useEffect, useRef } from "react";
import "./promo.scss";
import Card from "../Card/Card";
import { createCDNFromStrapi } from "../../utils/image";

export default function Promo({ allPageDatas, promoCardsDatas }) {
  const cardItemsRef = useRef(null);

  useEffect(() => {
    const cardItemsContainer = cardItemsRef.current;
    let animationFrameId;
    let scrollPosition = 0;
    let scrollSpeed = 0.5;
    let isDragging = false;
    let touchStartX = 0;
    let touchMoveX = 0;

    const handleTouchStart = (event) => {
      isDragging = true;
      touchStartX = event.touches[0].clientX;
    };

    const handleTouchMove = (event) => {
      if (!isDragging) return;

      touchMoveX = event.touches[0].clientX;
      const touchDeltaX = touchMoveX - touchStartX;

      cardItemsContainer.scrollLeft -= touchDeltaX;
      scrollPosition = cardItemsContainer.scrollLeft;

      touchStartX = touchMoveX;
    };

    const handleTouchEnd = () => {
      isDragging = false;
    };

    const animateScroll = () => {
      cardItemsContainer.scrollTo(scrollPosition, 0);
      scrollPosition += scrollSpeed;

      if (scrollPosition >= cardItemsContainer.scrollWidth) {
        scrollPosition = 0;
        setTimeout(() => {
          animateScroll();
        }, 1000);
      } else {
        animationFrameId = requestAnimationFrame(animateScroll);
      }
    };

    const startAutoScroll = () => {
      animateScroll();
    };

    const stopAutoScroll = () => {
      cancelAnimationFrame(animationFrameId);
    };

    cardItemsContainer.addEventListener("mouseenter", stopAutoScroll);
    cardItemsContainer.addEventListener("mouseleave", startAutoScroll);
    cardItemsContainer.addEventListener("touchstart", handleTouchStart);
    cardItemsContainer.addEventListener("touchmove", handleTouchMove);
    cardItemsContainer.addEventListener("touchend", handleTouchEnd);

    startAutoScroll();

    return () => {
      cardItemsContainer.removeEventListener("mouseenter", stopAutoScroll);
      cardItemsContainer.removeEventListener("mouseleave", startAutoScroll);
      cardItemsContainer.removeEventListener("touchstart", handleTouchStart);
      cardItemsContainer.removeEventListener("touchmove", handleTouchMove);
      cardItemsContainer.removeEventListener("touchend", handleTouchEnd);
      stopAutoScroll();
    };
  }, []);

  return (
    <div id="Promo" className="promo-section">
      <div className="promo-main container-fluid-wrapper">
        <h2
          className="promo-header"
          dangerouslySetInnerHTML={{ __html: allPageDatas?.Promo_title }}
        ></h2>
        <p className="promo-content">{allPageDatas?.Promo_content}</p>
      </div>
      <img
        className="bg-img-cover"
        src={allPageDatas?.Promo_bg_img?.data?.attributes?.url}
        alt="bg-img"
      />
      <div className="card-items" ref={cardItemsRef}>
        {promoCardsDatas?.map((item, index) => {
          return (
            <Card
              key={index}
              cardLink={item?.attributes?.Promo_card_link}
              cardTitle={item?.attributes?.Promo_card_title}
              cardContent={item?.attributes?.Promo_card_content}
              imgLink={createCDNFromStrapi(
                item?.attributes?.Promo_card_bg_img?.data?.attributes
              )}
              shareImg={createCDNFromStrapi(
                item?.attributes?.Promo_card_app_img?.data[0]?.attributes
              )}
            />
          );
        })}
      </div>
    </div>
  );
}
