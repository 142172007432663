import React from "react";
import "./sections.scss";
import { createCDNFromStrapi } from "../../utils/image";

export default function Showcase({ allPageDatas, showcaseDatas }) {
  return (
    <div id="ShowCase" className="showcase-section">
      <div className={"container-fluid-wrapper"}>
        <div className="showcase-content-area">
          <h2 className={`showcase-header`}>{allPageDatas?.Showcase_title}</h2>
          <p className={`showcase-content`}>{allPageDatas?.Showcase_content}</p>
          <div className="showcase-card-row">
            {showcaseDatas?.map((item, index) => {
              return (
                <div key={index} className="showcase-card-col">
                  <a href={item?.attributes?.showcase_game_link}>
                    <img
                      src={createCDNFromStrapi(
                        item?.attributes?.Showcase_game_img?.data?.attributes
                      )}
                      alt="showcase-brands-img"
                    />
                    <p>{item?.attributes?.Showcase_game_title}</p>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
