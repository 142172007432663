import React, { useEffect, useState } from "react";
import {
  AllPageDatasRequest,
  PromoCardsDatasRequest,
  AboutDatasRequest,
  ShowcaseDatasRequest,
  TeamItemsDatasRequest,
  FooterSocialDatasRequest,
  headerListDatasRequest,
} from "./utils/dataService";
import { createCDNFromStrapi } from "./utils/image";
import Loading from "./components/Sections/Loading";

import Header from "./components/Header/Header";
import Promo from "./components/Promo/Promo";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Sections/Hero";
import About from "./components/Sections/About";
import Showcase from "./components/Sections/Showcase";
import Team from "./components/Sections/Team";
import GetInTouch from "./components/Sections/GetInTouch";

function App() {
  const [allPageDatas, setAllPageDatas] = useState(null);
  const [promoCardsDatas, setPromoCardsDatas] = useState(null);
  const [aboutDatas, setAboutDatas] = useState(null);
  const [footerSocialDatas, setFooterSocialDatas] = useState(null);
  const [showcaseDatas, setShowcaseDatas] = useState(null);
  const [teamItemsDatas, setTeamItemsDatas] = useState(null);
  const [headerListDatas, setHeaderListDatas] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const allPageDatas = await AllPageDatasRequest();
      const promoCardsDatas = await PromoCardsDatasRequest();
      const aboutDatas = await AboutDatasRequest();
      const showcaseDatas = await ShowcaseDatasRequest();
      const teamItemsDatas = await TeamItemsDatasRequest();
      const footerSocialDatas = await FooterSocialDatasRequest();
      const headerListDatas = await headerListDatasRequest();

      setAllPageDatas(allPageDatas);
      setPromoCardsDatas(promoCardsDatas);
      setAboutDatas(aboutDatas);
      setShowcaseDatas(showcaseDatas);
      setTeamItemsDatas(teamItemsDatas);
      setFooterSocialDatas(footerSocialDatas);
      setHeaderListDatas(headerListDatas);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header
        allPageDatas={allPageDatas}
        headerListDatas={headerListDatas}
        logoImg={createCDNFromStrapi(
          allPageDatas?.header_logo?.data?.attributes
        )}
        logoIconImg={createCDNFromStrapi(
          allPageDatas?.hamburger_icon?.data?.attributes
        )}
      />
      <Promo allPageDatas={allPageDatas} promoCardsDatas={promoCardsDatas} />
      <Hero
        allPageDatas={allPageDatas}
        heroImg={createCDNFromStrapi(allPageDatas?.Hero_img?.data?.attributes)}
      />
      <About
        allPageDatas={allPageDatas}
        aboutDatas={aboutDatas}
        aboutImg={createCDNFromStrapi(
          allPageDatas?.About_img?.data?.attributes
        )}
      />
      <Showcase allPageDatas={allPageDatas} showcaseDatas={showcaseDatas} />
      <Team allPageDatas={allPageDatas} teamItemsDatas={teamItemsDatas} />
      <GetInTouch allPageDatas={allPageDatas} />
      <Footer
        allPageDatas={allPageDatas}
        footerSocialDatas={footerSocialDatas}
        headerListDatas={headerListDatas}
        logoImg={createCDNFromStrapi(
          allPageDatas?.header_logo?.data?.attributes
        )}
      />
    </>
  );
}

export default App;
