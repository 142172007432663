import React from "react";
import "./sections.scss";
import { createCDNFromStrapi } from "../../utils/image";

export default function About({ aboutDatas, allPageDatas, aboutImg }) {
  return (
    <div id="AboutUs" className="about-section">
      <div className={"about-d-flex container-fluid-wrapper"}>
        <div className="about-content-area">
          <div className="about-left-area">
            <h2 className={`about-header`}>{allPageDatas?.About_title}</h2>
            <p className={`about-content`}>{allPageDatas?.About_content}</p>
            <div className="about-client">
              {aboutDatas?.map((item, index) => {
                return (
                  <span key={index} className="about-client-item">
                    <img
                      className="about-client-item-img"
                      src={
                        item?.attributes?.About_client_img?.data?.attributes
                          ?.url
                      }
                      alt="about-img"
                    />
                    <p className="about-client-name">
                      {item?.attributes?.About_client_name}
                    </p>
                    <p className="about-client-role">
                      {" "}
                      {item?.attributes?.About_client_role}
                    </p>
                  </span>
                );
              })}
            </div>
          </div>
          <div className="about-img-main">
            <img className="about-img" src={aboutImg} alt="about-img" />
          </div>
        </div>
      </div>
      <div className="about-img-main-hide">
        <img src={aboutImg} alt="about-img" />
      </div>
    </div>
  );
}
