import React from "react";
import "./card.scss";

export default function Card({
  cardLink,
  cardTitle,
  cardContent,
  imgLink,
  shareImg,
}) {
  return (
    <a href={cardLink} className="card-img-container">
      <img className="card-img" src={imgLink} alt="card-img" />
      <div className="card-img-overlay">
        <h6 className="card-header">{cardTitle}</h6>
        <p className="card-content">{cardContent}</p>
        <img className="card-share" src={shareImg} alt="card-img" />
      </div>
    </a>
  );
}
