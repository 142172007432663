import React, { useEffect, useState } from "react";
import "./header.scss";
// import { createCDNFromStrapi } from "../../utils/image";

export default function Header({
  allPageDatas,
  headerListDatas,
  logoImg,
  logoIconImg,
}) {
  const [openMenu, setOpenMenu] = React.useState(false);

  const openedMenu = () => {
    setOpenMenu((prevOpenMenu) => !prevOpenMenu);
  };

  const [isVisible, setIsVisible] = useState(true);

  const scroll = () => {
    window.location.href = "#GetInTouch";
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollY > 10000) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div className="header-section scroll-header">
          <div className={" container-fluid-wrapper"}>
            {" "}
            <div>
              <a href="/#">
                <img
                  className="header-brand-logo"
                  width={171}
                  src={logoImg}
                  alt="brand-logo"
                />
              </a>
            </div>
            <div>
              <ul className={"flex-container-right"}>
                {headerListDatas?.map((item, index) => {
                  return (
                    <li key={index} className={"header-list-items"}>
                      <a href={item?.attributes?.list_btn}>
                        {item?.attributes?.list}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="header-hamburger-icon">
              <a onClick={openedMenu} href="/#">
                <img
                  height={22}
                  width={22}
                  src={logoIconImg}
                  alt="hamburger-icon"
                />
              </a>
            </div>
          </div>

          <ul className={openMenu ? "header-hamburger-menu-active" : "hide"}>
            {headerListDatas?.map((item, index) => {
              return (
                <li key={index} className={"header-list-items-active"}>
                  <a href={item?.attributes?.list_btn}>
                    {item?.attributes?.list}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
