const API = {
  allPageDatas:
    "page-contents?populate=header_logo,hamburger_icon,Hero_img,About_img,Promo_bg_img",
  aboutDatas: "about-clients?populate=About_client_img",
  headerListDatas: "header-lists?populate=",
  footerSocialDatas: "footer-socials?populate=footer_social_img",
  promoCardsDatas: "promo-cards?populate=Promo_card_bg_img,Promo_card_app_img",
  showcaseDatas:
    "showcase-games?populate=Showcase_game_img&pagination[limit]=300",
  teamItemsDatas: "team-items?populate=Team_item_img",
  contactData: "contacts",
  CDN: "https://d16sbrj48smgf9.cloudfront.net/",
};

export default API;
