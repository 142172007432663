import React from "react";
import "./sections.scss";
import { createCDNFromStrapi } from "../../utils/image";

export default function Team({ allPageDatas, teamItemsDatas }) {
  return (
    <div id="Team" className="team-section">
      <div className={"container-fluid-wrapper"}>
        <div className="team-content-area">
          <p className={`team-content`}>{allPageDatas?.Team_content}</p>
          <h2 className={`team-header`}>{allPageDatas?.Team_title}</h2>
          <div className="team-card-row">
            {teamItemsDatas?.map((item, index) => {
              return (
                <div key={index} className="team-card-col">
                  <img
                    src={createCDNFromStrapi(
                      item?.attributes?.Team_item_img?.data?.attributes
                    )}
                    alt="team-brands-img"
                  />
                  <h6>{item?.attributes?.Team_item_title}</h6>
                  <p>{item?.attributes?.Team_item_content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
