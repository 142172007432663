import axios from "axios";
import Constant from "../Constant";
import API from "./api";

const AllPageDatasRequest = async () => {
  try {
    const AllPageDatas = await axios.get(`${Constant.API}${API.allPageDatas}`);
    return AllPageDatas?.data?.data[0]?.attributes;
  } catch (error) {
    console.log("AXIOS ERROR", error);
    return null;
  }
};

const PromoCardsDatasRequest = async () => {
  try {
    const PromoCardsDatas = await axios.get(
      `${Constant.API}${API.promoCardsDatas}`
    );
    return PromoCardsDatas?.data?.data;
  } catch (error) {
    console.log("AXIOS ERROR", error);
    return null;
  }
};

const AboutDatasRequest = async () => {
  try {
    const AboutDatas = await axios.get(`${Constant.API}${API.aboutDatas}`);
    return AboutDatas?.data?.data;
  } catch (error) {
    console.log("AXIOS ERROR", error);
    return null;
  }
};

const ShowcaseDatasRequest = async () => {
  try {
    const ShowcaseDatas = await axios.get(
      `${Constant.API}${API.showcaseDatas}`
    );
    return ShowcaseDatas?.data?.data;
  } catch (error) {
    console.log("AXIOS ERROR", error);
    return null;
  }
};

const TeamItemsDatasRequest = async () => {
  try {
    const TeamItemsDatas = await axios.get(
      `${Constant.API}${API.teamItemsDatas}`
    );
    return TeamItemsDatas?.data?.data;
  } catch (error) {
    console.log("AXIOS ERROR", error);
    return null;
  }
};

const FooterSocialDatasRequest = async () => {
  try {
    const FooterSocialDatas = await axios.get(
      `${Constant.API}${API.footerSocialDatas}`
    );
    return FooterSocialDatas?.data?.data;
  } catch (error) {
    console.log("AXIOS ERROR", error);
    return null;
  }
};

const headerListDatasRequest = async () => {
  try {
    const headerListDatas = await axios.get(
      `${Constant.API}${API.headerListDatas}`
    );
    return headerListDatas?.data?.data;
  } catch (error) {
    console.log("AXIOS ERROR", error);
    return null;
  }
};

export {
  AllPageDatasRequest,
  PromoCardsDatasRequest,
  AboutDatasRequest,
  ShowcaseDatasRequest,
  TeamItemsDatasRequest,
  FooterSocialDatasRequest,
  headerListDatasRequest,
};
