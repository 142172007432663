import React from "react";

const Loading = () => {
  return (
    <div className="Loading-container">
      <div class="spinner-wrapper">
        <div class="spinner">
          <div class="sk-folding-cube">
            <div class="sk-cube1 sk-cube"></div>
            <div class="sk-cube2 sk-cube"></div>
            <div class="sk-cube4 sk-cube"></div>
            <div class="sk-cube3 sk-cube"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
