import React from "react";
import contactListImg1 from "../../img/contact-mail.svg";
import contactListImg2 from "../../img/call.svg";
import contactListImg3 from "../../img/location_on.svg";
import axios from "axios";
import api from "../../utils/api";
import Constant from "../../Constant";
import { validateEmail } from "../../utils/validateEmail";
import {
  toastConfigurationError,
  toastConfigurationSuccess,
} from "../../utils/toastConfiguration";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Input = ({ allPageDatas }) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const nameChanged = (event) => {
    setName(event.target.value);
  };
  const emailChanged = (event) => {
    setEmail(event.target.value);
  };
  const messageChanged = (event) => {
    setMessage(event.target.value);
  };
  const showErrorToast = (_message) => {
    toast.error(_message, toastConfigurationError);
  };
  const showSuccessToast = (_message) => {
    toast.success(_message, toastConfigurationSuccess);
  };

  const Send = () => {
    if (name.length < 2) {
      showErrorToast("Please enter your name!");
      return;
    }

    if (email.length === 0) {
      showErrorToast("Please Enter Email!");
      return;
    }

    if (!validateEmail(email)) {
      showErrorToast("Please Enter Valid Email!");
      return;
    }

    if (message.length < 10) {
      showErrorToast("Please enter your message!");
      return;
    }

    axios
      .post(
        `https://xtgm7zy6w3hi7v4ozzxyudzzuq0rhzwj.lambda-url.us-east-1.on.aws/`,
        {
          name: name,
          email: email,
          message: message,
          toMail: "hilmi@nitrogengames.com",
          companyName: "NitrogenGames",
          formName: "İletişim",
        }
      )
      .then(() => {
        setName("");
        setEmail("");
        setMessage("");
        showSuccessToast("Success Message!");
      })
      .catch((e) => {
        alert("error");
      });
  };

  return (
    <div id="GetInTouch" className="getInTouch-input-area">
      <div className="getInTouch-input-item">
        <label className="getInTouch-input-label">
          {allPageDatas?.GetInTouch_label_1}
        </label>
        <input
          className={"getInTouch-input-common"}
          type="text"
          onChange={nameChanged}
          value={name}
        />
      </div>
      <div>
        <div className="getInTouch-input-item">
          <label className="getInTouch-input-label">
            {allPageDatas?.GetInTouch_label_2}
          </label>
          <input
            className={"getInTouch-input-common"}
            type="email"
            onChange={emailChanged}
            value={email}
          />
        </div>
        <div>
          <label className="getInTouch-input-label getInTouch-text-label">
            {allPageDatas?.GetInTouch_label_3}
          </label>
        </div>
        <textarea
          className="getInTouch-text-area"
          data-type="message"
          id="message"
          name="Note"
          onChange={messageChanged}
          value={message}
          data-rule-required="true"
          data-msg-required="Required"
        ></textarea>
      </div>
      <ToastContainer />
      <button onClick={Send} className="common-btn">
        {allPageDatas?.GetInTouch_btn}
      </button>
    </div>
  );
};

const Contact = ({ allPageDatas }) => {
  const contactList = [
    {
      contactList_img: contactListImg1,
      list_item: allPageDatas?.GetInTouch_mail,
    },
    {
      contactList_img: contactListImg2,
      list_item: allPageDatas?.GetInTouch_phone,
    },
    {
      contactList_img: contactListImg3,
      list_item: allPageDatas?.GetInTouch_location,
    },
  ];
  return (
    <div className="getInTouch-contact-area">
      <h3>{allPageDatas?.GetInTouch_title}</h3>
      {contactList?.map((item, index) => {
        return (
          <div key={index} className="getInTouch-contact-item">
            <img src={item?.contactList_img} alt="contact-icon-img" />
            <span
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{ __html: item?.list_item }}
            ></span>
          </div>
        );
      })}
    </div>
  );
};

const GetInTouch = ({ allPageDatas }) => {
  return (
    <div id="GetInTouch" className="getInTouch-bg">
      <div className="getInTouch-section">
        <Contact allPageDatas={allPageDatas} />
        <Input allPageDatas={allPageDatas} />
      </div>
    </div>
  );
};

export default GetInTouch;
