import React from "react";
import "./footer.scss";
import { createCDNFromStrapi } from "../../utils/image";

export default function Footer({
  allPageDatas,
  footerSocialDatas,
  headerListDatas,
  logoImg,
}) {
  return (
    <div className="footer-section">
      <div className={"container-fluid-wrapper"}>
        <div className="footer-top-content">
          <div className="footer-logo">
            <a href="/#">
              <img width={194} src={logoImg} alt="logo" />
            </a>
          </div>
          <div className="footer-list-area">
            <ul className={"flex-container-right"}>
              {headerListDatas?.map((item, index) => {
                return (
                  <li key={index} className={"footer-list-item"}>
                    <a href={item?.attributes?.list_btn}>
                      {item?.attributes?.list}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="footer-bottom-content">
          <div className={"flex-container-right footer-social-area"}>
            {footerSocialDatas?.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item?.attributes?.footer_social_link}
                  rel="noreferrer"
                  className={"footer-social-item"}
                >
                  <img
                    src={createCDNFromStrapi(
                      item?.attributes?.footer_social_img?.data?.attributes
                    )}
                    alt="social-icon"
                  />
                </a>
              );
            })}
          </div>
          <p className="footer-pp">{allPageDatas?.Footer_PrivacyPolicy}</p>
        </div>
      </div>
    </div>
  );
}
