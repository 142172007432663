import React from "react";
import "./sections.scss";
// import { createCDNFromStrapi } from "../../utils/image";

export default function Hero({ allPageDatas, heroImg }) {
  return (
    <div id="Hero" className="hero-section">
      <div className={"container-fluid-wrapper hero-d-flex"}>
        <div className="hero-content-area">
          <h2 className={`hero-header`}>{allPageDatas?.Hero_title}</h2>
          <p className={`hero-content`}>{allPageDatas?.Hero_content}</p>
          <button className="common-btn">
            <a href={allPageDatas?.Hero_btn_link}>{allPageDatas?.Hero_btn}</a>
          </button>
        </div>
        <div>
          <img className="hero-img" src={heroImg} alt="hero-img" />
        </div>
      </div>
    </div>
  );
}
